import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#132933',
    color: '#ffffff',
    fontSize: '0.875rem',
    maxWidth: 500,
  },
});

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));
const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#FB5718', // Thumb color when checked
    '&:hover': {
      backgroundColor: 'rgba(251, 87, 24, 0.08)', // Light hover effect when checked
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#FB5718', // Track color when checked
  },
}));

const FiResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedData, setSelectedData] = useState(
    location.state?.selectedData || []
  );
  const [isCalculationExpanded, setCalculationExpanded] = useState(false); // State for toggle
  const [isResultExpanded, setResultExpanded] = useState(false); // State for Result toggle
  const [summaryData, setSummaryData] = useState(
    location.state?.summaryData || {}
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const userEmail = localStorage.getItem('email');
  // Add Net Interest Margin row
  //  useEffect(() => {
  //   setSelectedData((prevData) => [
  //     ...prevData,
  //     {
  //       resultLabel: 'Net Interest Margin (%)',
  //       y3: '',
  //       y4: '',
  //       tooltip: 'Net Interest Income / Average Earning Assets',
  //       limit: 3,
  //       afreximbankBenchmark: '<63%'
  //     },
  //   ]);
  // }, []);

  // Function to format numbers with commas for display
  const formatNumberWithCommas = (value) => {
    const num = Number(String(value).replace(/,/g, ''));
    if (!isNaN(num)) {
      const formattedValue = num.toLocaleString('en-US');
      if (num < 0) {
        return `(${formattedValue.replace('-', '')})`;
      }
      return formattedValue;
    }
    if (value) {
      return value;
    }
  };

  const toggleCalculation = () => {
    setCalculationExpanded((prev) => !prev);
  };
  const toggleResult = () => {
    setResultExpanded((prev) => !prev);
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadReport = () => {
    const countryName =
      location.state?.selectedCountry?.displayName || 'Country';
    const bankName = location.state?.selectedBank?.bankName || 'Bank';
    const fileName = `${countryName}.pdf`;
    console.log(countryName + ' ' + bankName);
    if (location.state?.reportUrl?.length) {
      location.state.reportUrl.forEach((item) => {
        window.open(item, '_blank');
      });
    } else {
      // Open the fallback URL
      const link =
        'https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/financial/' +
        countryName +
        '.pdf';
      window.open(link, '_blank');
    }
  };

  const downloadPDF = () => {
    const countryName =
      location.state?.selectedCountry?.displayName || 'Country';
    const bankName = location.state?.selectedBank?.bankName || 'Bank';
    const fileName = `${countryName}_${bankName}.pdf`;

    const doc = new jsPDF({ orientation: 'landscape' });
    doc.text('Financial Results', 20, 10);

    const financialTable = document.getElementById('financial-table');
    const resultTable = document.getElementById('result-table');
    const summaryTable = document.getElementById('summary-table');
    const calculationTable = document.getElementById('calculation-table');

    const headerStyles = {
      fillColor: [0, 128, 0], // RGB for green
      textColor: [255, 255, 255], // White text
      halign: 'center',
      valign: 'middle',
    };

    if (financialTable) {
      doc.autoTable({
        html: '#financial-table',
        headStyles: headerStyles,
      });
    }

    if (resultTable) {
      doc.autoTable({
        html: '#result-table',
        startY: doc.lastAutoTable.finalY + 10,
        headStyles: headerStyles,
      });
    }

    if (summaryTable) {
      const summaryData = location.state?.summaryData || {};
      const summaryRows = Object.entries(summaryData)
        .filter(
          ([key]) =>
            key !== 'title' && key !== 'conclusion' && key !== 'titleTooltip'
        )
        .map(([key, value], index) => [
          index + 1,
          value.summary,
          value.findings,
          value.indicator === 'green'
            ? 'Green'
            : value.indicator === 'amber'
            ? 'Amber'
            : value.indicator === 'red'
            ? 'Red'
            : value.indicator,
        ]);

      doc.autoTable({
        head: [['#', 'Summary', 'Findings', 'Indicator']],
        body: summaryRows,
        startY: doc.lastAutoTable.finalY + 10,
        headStyles: headerStyles,
      });
    }

    if (calculationTable) {
      doc.autoTable({
        html: '#calculation-table',
        startY: doc.lastAutoTable.finalY + 10,
        headStyles: headerStyles,
      });
    }

    doc.save(fileName);
    handleClose();
  };

  const downloadCSV = () => {
    const countryName =
      location.state?.selectedCountry?.displayName || 'Country';
    const bankName = location.state?.selectedBank?.bankName || 'Bank';
    const fileName = `${countryName}_${bankName}.csv`;
    const currencyName = location?.state?.currencyName || 'Currency';

    let csvContent = 'data:text/csv;charset=utf-8,';

    // Add selected data
    csvContent += 'Selected Data\n';
    csvContent +=
      `In ${currencyName} '000,` +
      location.state.header.map((item) => `YE ${item}`).join(',') +
      '\n';
    selectedData.forEach((row) => {
      csvContent += `${row.label},${row.y9},${row.y1},${row.y2},${row.y12}\n`;
    });

    // Add result data
    csvContent += '\nResult Data\n';
    csvContent +=
      `In ${currencyName} '000,` +
      location.state.header.map((item) => `YE ${item}`).join(',') +
      ',Afreximbank benchmark,Industry benchmark YE 2022,Industry benchmark YE 2023,Comments\n';
    selectedData.forEach((row) => {
      csvContent += `${row.resultLabel},${row.y10},${row.y3},${row.y4},${
        row.y13
      },${row.afreximbankBenchmark
        ?.replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')}\n`;
    });

    // Add summary data
    csvContent += '\nSummary Data\n';
    csvContent += 'Summary,Findings,Indicator\n';
    Object.entries(summaryData).forEach(([key, value]) => {
      if (key !== 'title' && key !== 'conclusion' && key !== 'titleTooltip') {
        csvContent += `"${value.summary}","${value.findings}",${value.indicator}\n`;
      }
    });

    // Add calculation data
    csvContent += '\nCalculation Data\n';
    csvContent +=
      `In ${currencyName} '000,` +
      location.state.header.map((item) => `YE ${item}`).join(',') +
      '\n';
    selectedData.forEach((row) => {
      if (row.calculationLabel) {
        csvContent += `${row.calculationLabel},${row.y11},${row.y5},${row.y6},${row.y14}\n`;
      }
    });
    if (location.state?.calculation?.y5) {
      csvContent += `Total Capital (shareholders equity),${location.state.calculation.y5},${location.state.calculation.y6}\n`;
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    handleClose();
  };

  const downloadXML = () => {
    const countryName =
      location.state?.selectedCountry?.displayName || 'Country';
    const bankName = location.state?.selectedBank?.bankName || 'Bank';
    const fileName = `${countryName}_${bankName}.xml`;

    let xmlContent =
      '<?xml version="1.0" encoding="UTF-8"?>\n<FinancialResults>\n';

    // Add selected data
    xmlContent += '  <SelectedData>\n';
    selectedData.forEach((row) => {
      xmlContent += `    <Result>\n      <Label>${row?.label}</Label>\n      <Y9>${row?.y9}</Y9>\n      <Y1>${row?.y1}</Y1>\n      <Y2>${row?.y2}</Y2>\n      <Y12>${row?.y12}</Y12>\n    </Result>\n`;
    });
    xmlContent += '  </SelectedData>\n';

    // Add result data
    xmlContent += '  <ResultData>\n';
    selectedData.forEach((row) => {
      xmlContent += `    <Result>\n      <Label>${
        row?.resultLabel
      }</Label>\n      <Y10>${row?.y10}</Y10>\n      <Y3>${
        row?.y3
      }</Y3>\n      <Y4>${row?.y4}</Y4>\n      <Y13>${
        row?.y13
      }</Y13>\n      <AfreximbankBenchmark>${
        row.afreximbankBenchmark
      }</AfreximbankBenchmark>\n      <IndustryBenchmark2023>${
        row?.industryBenchmark2023 || ''
      }</IndustryBenchmark2023>\n      <Comments>${
        row?.comments || ''
      }</Comments>\n    </Result>\n`;
    });
    xmlContent += '  </ResultData>\n';

    // Add summary data
    xmlContent += '  <SummaryData>\n';
    Object.entries(summaryData).forEach(([key, value]) => {
      if (key !== 'title' && key !== 'conclusion' && key !== 'titleTooltip') {
        xmlContent += `    <Summary>\n      <Label>${key}</Label>\n      <SummaryText>${value?.summary}</SummaryText>\n      <Findings>${value?.findings}</Findings>\n      <Indicator>${value?.indicator}</Indicator>\n    </Summary>\n`;
      }
    });
    xmlContent += '  </SummaryData>\n';

    // Add calculation data
    xmlContent += '  <CalculationData>\n';
    selectedData.forEach((row) => {
      if (row.calculationLabel) {
        xmlContent += `    <Calculation>\n      <Label>${row.calculationLabel}</Label>\n      <Y11>${row?.y11}</Y11>\n      <Y5>${row?.y5}</Y5>\n      <Y6>${row?.y6}</Y6>\n      <Y14>${row?.y14}</Y14>\n    </Calculation>\n`;
      }
    });
    if (location.state?.calculation?.y5) {
      xmlContent += `    <Calculation>\n      <Label>TotalCapitalShareholdersEquity</Label>\n      <Y5>${location.state.calculation.y5}</Y5>\n      <Y6>${location.state.calculation.y6}</Y6>\n    </Calculation>\n`;
    }
    xmlContent += '  </CalculationData>\n';

    xmlContent += '</FinancialResults>';
    const blob = new Blob([xmlContent], { type: 'application/xml' });
    saveAs(blob, fileName);
    handleClose();
  };
  const downloadDOC = () => {
    const countryName =
      location.state?.selectedCountry?.displayName || 'Country';
    const bankName = location.state?.selectedBank?.bankName || 'Bank';
    const fileName = `${countryName}_${bankName}.doc`;
    const currencyName = location?.state?.currencyName || 'Currency';

    // Start the HTML structure
    let docContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8">
        <title>${fileName}</title>
        <style>
          table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
          }
          th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f4f4f4;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
  `;

    // Add Selected Data as a table
    docContent += `<h2>Selected Data</h2>`;
    docContent += `<table>`;
    docContent +=
      `<tr><th>In ${currencyName} '000</th>` +
      location.state.header.map((item) => `<th>YE ${item}</th>`).join('') +
      `</tr>`;
    selectedData.forEach((row) => {
      docContent += `<tr>
      <td>${row.label || '-'}</td>
      <td>${row.y9 || '-'}</td>
      <td>${row.y1 || '-'}</td>
      <td>${row.y2 || '-'}</td>
      <td>${row.y12 || '-'}</td>
      <td>${row.y15 || '-'}</td>
    </tr>`;
    });
    docContent += `</table>`;

    // Add Result Data as a table
    docContent += `<h2>Result Data</h2>`;
    docContent += `<table>`;
    docContent +=
      `<tr><th>In ${currencyName} '000</th>` +
      location.state.header.map((item) => `<th>YE ${item}</th>`).join('') +
      `<th>Afreximbank benchmark</th><th>Industry benchmark YE 2022</th><th>Industry benchmark YE 2023</th><th>Comments</th></tr>`;
    selectedData.forEach((row) => {
      docContent += `<tr>
      <td>${row.resultLabel || '-'}</td>
      <td>${row.y10 || '-'}</td>
      <td>${row.y3 || '-'}</td>
      <td>${row.y4 || '-'}</td>
      <td>${row.y13 || '-'}</td>
      <td>${row.y16 || '-'}</td>
      <td>${
        row.afreximbankBenchmark?.replace(/&lt;/g, '<').replace(/&gt;/g, '>') ||
        '-'
      }</td>
    </tr>`;
    });
    docContent += `</table>`;

    // Add Summary Data as a table
    docContent += `<h2>Summary Data</h2>`;
    docContent += `<table>`;
    docContent += `<tr><th>Summary</th><th>Findings</th><th>Indicator</th></tr>`;
    Object.entries(summaryData).forEach(([key, value]) => {
      if (key !== 'title' && key !== 'conclusion' && key !== 'titleTooltip') {
        docContent += `<tr>
        <td>${value.summary || '-'}</td>
        <td>${value.findings || '-'}</td>
        <td>${value.indicator || '-'}</td>
      </tr>`;
      }
    });
    docContent += `</table>`;

    // Add Calculation Data as a table
    docContent += `<h2>Calculation Data</h2>`;
    docContent += `<table>`;
    docContent +=
      `<tr><th>In ${currencyName} '000</th>` +
      location.state.header.map((item) => `<th>YE ${item}</th>`).join('') +
      `</tr>`;
    selectedData.forEach((row) => {
      if (row.calculationLabel) {
        docContent += `<tr>
        <td>${row.calculationLabel || '-'}</td>
        <td>${row.y11 || '-'}</td>
        <td>${row.y5 || '-'}</td>
        <td>${row.y6 || '-'}</td>
        <td>${row.y14 || '-'}</td>
        <td>${row.y17 || '-'}</td>
      </tr>`;
      }
    });
    if (location.state?.calculation?.y5) {
      docContent += `<tr>
      <td>Total Capital (shareholders equity)</td>
      <td>${location.state.calculation.y5 || '-'}</td>
      <td>${location.state.calculation.y6 || '-'}</td>
    </tr>`;
    }
    docContent += `</table>`;

    // Close the HTML structure
    docContent += `
      </body>
    </html>
  `;

    // Convert content to Blob and trigger download
    const blob = new Blob([docContent], { type: 'application/msword' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    handleClose();
  };

  return (
    <>
      <Grid container>
        {/* Page 1 table */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
              padding: '16px',
              borderRadius: '8px',
            }}
          >
            <Stack>
              <Box display="flex" mt={2} mb={2} gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography> Country :- </Typography>
                  <Box
                    height={'40px'}
                    width={'40px'}
                    borderRadius={'50%'}
                    component={'img'}
                    src={location.state?.selectedCountry?.icon}
                    sx={{ border: '1px solid #ccc' }}
                  />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {location.state?.selectedBank?.country
                      .charAt(0)
                      .toUpperCase() +
                      location.state?.selectedBank?.country.slice(1)}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography> Bank :- </Typography>
                  <Box
                    height={'40px'}
                    width={'40px'}
                    borderRadius={'50%'}
                    component={'img'}
                    src={location.state?.selectedBank?.icon}
                    sx={{ border: '1px solid #ccc' }}
                  />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {location.state?.selectedBank?.bankName}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Input
              </Typography>
            </Stack>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadClick}
            >
              Download
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={downloadPDF}>Download PDF</MenuItem>
              <MenuItem onClick={downloadCSV}>Download CSV</MenuItem>
              <MenuItem onClick={downloadXML}>Download XML</MenuItem>
              <MenuItem onClick={downloadDOC}>Download DOC</MenuItem>
              {location.state?.reportUrl?.length ? (
                <>
                  {location.state?.reportUrl.map((item) => (
                    <MenuItem onClick={() => window.open(item.link)}>
                      Download {item?.year}
                    </MenuItem>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Menu>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
              id="financial-table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '100px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '200px' }}>
                    In {location?.state?.currencyName} '000
                  </HeadTableCell>
                  {location.state?.header?.length &&
                    location.state?.header.map((item, idx) => (
                      <HeadTableCell
                        key={idx}
                        align="right"
                        sx={{ width: '150px' }}
                      >
                        YE {item}
                      </HeadTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  background: '#001823',
                }}
              >
                {selectedData.map((row, index) => (
                  <>
                    {row.label && (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                          },
                        }}
                      >
                        <StyledTableCell align="center" sx={{ width: '100px' }}>
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{
                            width: '200px',
                            padding: '0px 20px !important',
                          }}
                        >
                          {row.label}
                        </StyledTableCell>
                        {row.label && (
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '150px',
                              padding: '0px 20px !important',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Box
                                sx={{ padding: '20px 12px' }}
                                color={
                                  typeof row.y9 !== 'string' && row.y9 < 0
                                    ? 'red'
                                    : 'inherit'
                                }
                              >
                                {row.y9 ? formatNumberWithCommas(row.y9) : '-'}
                              </Box>
                            </Box>
                          </StyledTableCell>
                        )}
                        {row.label && (
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '150px',
                              padding: '0px 20px !important',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Box
                                sx={{ padding: '20px 12px' }}
                                color={
                                  typeof row.y11 !== 'string' && row.y11 < 0
                                    ? 'red'
                                    : 'inherit'
                                }
                              >
                                {row.y1 ? formatNumberWithCommas(row.y1) : '-'}
                              </Box>
                            </Box>
                          </StyledTableCell>
                        )}
                        {row.label && (
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '150px',
                              padding: '0px 20px !important',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Box
                                sx={{ padding: '20px 12px' }}
                                color={
                                  typeof row.y2 !== 'string' && row.y2 < 0
                                    ? 'red'
                                    : 'inherit'
                                }
                              >
                                {row.y2 ? formatNumberWithCommas(row.y2) : '-'}
                                {row.y1 === 36 && (
                                  <CustomTooltip
                                    title={
                                      'Recovery on Non Perfoming Loans in 2022 resulted in Over provisioning of loans'
                                    }
                                    sx={{ color: '#000000' }}
                                    arrow
                                  >
                                    <InfoIcon
                                      style={{
                                        marginLeft: '8px',
                                        fontSize: '1.2rem',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </CustomTooltip>
                                )}

                                {row.y1 === 16268}

                                {row.y1 === 237227 && (
                                  <CustomTooltip
                                    title={
                                      'current unappropriated profits resulted in negative tier 2 capital.'
                                    }
                                    sx={{ color: '#000000' }}
                                    arrow
                                  >
                                    <InfoIcon
                                      style={{
                                        marginLeft: '8px',
                                        fontSize: '1.2rem',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </CustomTooltip>
                                )}
                              </Box>
                            </Box>
                          </StyledTableCell>
                        )}
                        {row.label && (
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '150px',
                              padding: '0px 20px !important',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Box
                                sx={{ padding: '20px 12px' }}
                                color={
                                  typeof row.y2 !== 'string' && row.y2 < 0
                                    ? 'red'
                                    : 'inherit'
                                }
                              >
                                {row.y12
                                  ? formatNumberWithCommas(row.y12)
                                  : '-'}
                              </Box>
                            </Box>
                          </StyledTableCell>
                        )}
                        {row.label && (
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '150px',
                              padding: '0px 20px !important',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Box
                                sx={{ padding: '20px 12px' }}
                                color={
                                  typeof row.y2 !== 'string' && row.y15 < 0
                                    ? 'red'
                                    : 'inherit'
                                }
                              >
                                {row.y15
                                  ? formatNumberWithCommas(row.y15)
                                  : '-'}
                              </Box>
                            </Box>
                          </StyledTableCell>
                        )}
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Result table */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body_semibold_20">Result</Typography>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
              id="result-table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '122px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '245px' }}>
                    In {location?.state?.currencyName} '000
                  </HeadTableCell>
                  {location.state?.header?.length &&
                    location.state?.header.map((item, idx) => (
                      <HeadTableCell
                        key={idx}
                        align="right"
                        sx={{ width: '150px' }}
                      >
                        FI scoring YE {item}
                      </HeadTableCell>
                    ))}
                  <HeadTableCell sx={{ width: '150px' }}>
                    Afreximbank benchmark
                  </HeadTableCell>
                  {/* <HeadTableCell sx={{ width: '150px' }}>
                    Industry benchmark YE 2022
                  </HeadTableCell> */}
                  <HeadTableCell sx={{ width: '150px' }}>
                    Industry benchmark
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '150px' }}>
                    Comments
                  </HeadTableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  background: '#001823',
                }}
              >
                {selectedData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="center" sx={{ width: '100px' }}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {index === 0 ? (
                        <>
                          {row.resultLabel}{' '}
                          <CustomTooltip
                            title={row?.tooltip}
                            sx={{ color: '#000000' }}
                            arrow
                          >
                            <InfoIcon
                              style={{
                                marginLeft: '8px',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                              }}
                            />
                          </CustomTooltip>
                          <Stack>
                            ({location.state?.selectedCountry?.displayName}{' '}
                            {row.resultLabel} Limit:- {row?.limit}%)
                          </Stack>
                        </>
                      ) : (
                        <>
                          {row.resultLabel}{' '}
                          <CustomTooltip
                            title={row?.tooltip}
                            sx={{ color: '#000000' }}
                            arrow
                          >
                            <InfoIcon
                              style={{
                                marginLeft: '8px',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                              }}
                            />
                          </CustomTooltip>
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        width: '185px',
                        padding: '0px 20px !important',
                        color: row?.color1,
                      }}
                    >
                      {row?.y10 ? formatNumberWithCommas(row.y10) : '-'}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        width: '185px',
                        padding: '0px 20px !important',
                        color: row?.color1,
                      }}
                    >
                      {isResultExpanded
                        ? row?.y7 && formatNumberWithCommas(row.y7)
                        : row?.y3 && formatNumberWithCommas(row.y3)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        width: '185px',
                        padding: '0px 20px !important',
                        color: row?.color1,
                      }}
                    >
                      {isResultExpanded
                        ? row?.y8 && formatNumberWithCommas(row.y8)
                        : row?.y4 && formatNumberWithCommas(row.y4)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        width: '185px',
                        padding: '0px 20px !important',
                        color: row?.color2,
                      }}
                    >
                      {row.y13 === '-1%' ? (
                        <>
                          {row?.y13 ? formatNumberWithCommas(row.y13) : '-'}
                          <CustomTooltip
                            title={
                              <span>
                                Material uncertainty relating to going
                                concern-unimpaired capital
                                <br />
                                The Bank of Ghana in a letter dated 8 June 2022
                                directed shareholders of the bank to submit a
                                capital restoration plan which addresses the
                                immediate capital requirement of the Bank in
                                accordance with section 106 (4) of the Banks and
                                Specialised Deposit Taking Institutions Act,
                                2016 (Act 930). Per the letter, the shareholders
                                of the Bank are required to inject more common
                                equity tier one capital in order to meet the
                                minimum capital adequacy ratio of 10% and an
                                unimpaired capital of not less than GH¢
                                400million not later than end-March 2023. The
                                Board of Directors are engaging the Shareholders
                                for a capital restoration plan, intended to
                                bridge the gap as per the directive of the Bank
                                of Ghana.
                                <br />
                                The Bank has unimpaired capital of negative
                                GH¢38.6million and Capital Adequacy ratio of
                                -0.21% which are below the minimum of GH¢
                                400million and 10% respectively, required by
                                Bank of Ghana. In February 2023, the Bank
                                exchanged its investments in Government Bonds
                                under the Domestic Debt Exchange Program (DDEP)
                                which affected the Bank with an impairment loss
                                of GHS 383.85million. The Regulator has
                                permitted:
                                <ul>
                                  <li>
                                    losses emanating from the Debt Exchange to
                                    be spread equally over a period of four (4)
                                    years for the purposes of Capital Adequacy
                                    Ratio (CAR) computation;
                                  </li>
                                  <li>
                                    Banks to restore the minimum paid-up capital
                                    as a result of any capital shortfall arising
                                    solely from the derecognition losses within
                                    a maximum period of four years.
                                  </li>
                                </ul>
                                Prior to considering the impact of the DDEP, the
                                unimpaired capital position of the Bank was GH¢
                                246.8million. As at 28 April 2023, there has not
                                been any additional capital injection. There is
                                material uncertainty on the going concern of the
                                Bank unless the Shareholders inject the needed
                                Capital to address the unimpaired capital
                                position and the CAR to be in line with the
                                regulatory requirement. Our opinion is not
                                modified in this respect.
                              </span>
                            }
                            sx={{ color: '#000000' }}
                            arrow
                          >
                            <InfoIcon
                              style={{
                                marginLeft: '8px',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                              }}
                            />
                          </CustomTooltip>
                        </>
                      ) : (
                        <>{row?.y13 ? formatNumberWithCommas(row.y13) : '-'}</>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        width: '185px',
                        padding: '0px 20px !important',
                        color: row?.color2,
                      }}
                    >
                      {row?.y16 ? formatNumberWithCommas(row.y16) : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.afreximbankBenchmark
                        ?.replace(/&lt;/g, '<')
                        .replace(/&gt;/g, '>') || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {' '}
                      {row.industryBenchmark2023 || '-'}
                    </StyledTableCell>
                    <StyledTableCell>-</StyledTableCell>
                    {/* <StyledTableCell></StyledTableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box mb={2} display="flex" flexDirection="column" gap={1}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Camel Summery & Rating
            </Typography>
            {/* <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="Body_semibold_20">
                {location.state?.summaryData?.title} (Afreximbank)
                <CustomTooltip
                  title={
                    <Box sx={{ p: 1 }}>
                      <Typography variant="body2" component="div">
                        <strong>Rating 1:</strong> Strong performance, effective
                        risk management, and stable operations.
                        <br />
                        <strong>Rating 2:</strong> Satisfactory performance with
                        minor weaknesses that are being addressed.
                        <br />
                        <strong>Rating 3:</strong> Fair performance with some
                        concerns that need attention.
                        <br />
                        <strong>Rating 4:</strong> Poor performance with
                        significant weaknesses that require immediate corrective
                        action.
                        <br />
                        <strong>Rating 5:</strong> Very weak performance with
                        severe deficiencies that pose a high risk to the bank's
                        stability.
                      </Typography>
                    </Box>
                  }
                  sx={{ color: '#000000' }}
                  arrow
                >
                  <InfoIcon
                    style={{
                      marginLeft: '8px',
                      fontSize: '1.2rem',
                      cursor: 'pointer',
                    }}
                  />
                </CustomTooltip>
              </Typography>
            </Box>
            <Typography
              variant="Body_normal_16"
              sx={{ wordBreak: 'break-word' }}
            >
              <strong>Conclusion:</strong>
              {location.state?.summaryData?.conclusion}
            </Typography> */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {location.state?.summaryData?.title}
                <CustomTooltip
                  title={
                    <Box sx={{ p: 1 }}>
                      <Typography variant="body2" component="div">
                        <strong>Rating 1:</strong> Strong performance, effective
                        risk management, and stable operations.
                        <br />
                        <strong>Rating 2:</strong> Satisfactory performance with
                        minor weaknesses that are being addressed.
                        <br />
                        <strong>Rating 3:</strong> Fair performance with some
                        concerns that need attention.
                        <br />
                        <strong>Rating 4:</strong> Poor performance with
                        significant weaknesses that require immediate corrective
                        action.
                        <br />
                        <strong>Rating 5:</strong> Very weak performance with
                        severe deficiencies that pose a high risk to the Bank's
                        stability.
                      </Typography>
                    </Box>
                  }
                  sx={{ color: '#000000' }}
                  arrow
                >
                  <InfoIcon
                    style={{
                      marginLeft: '8px',
                      fontSize: '1.2rem',
                      cursor: 'pointer',
                    }}
                  />
                </CustomTooltip>
              </Typography>
            </Box>

            <Typography
              variant="Body_normal_16"
              sx={{ wordBreak: 'break-word' }}
            >
              <strong>Conclusion:</strong>
              {location.state?.summaryData?.conclusion}
            </Typography>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
              id="summary-table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '130px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '265px', textAlign: 'center' }}>
                    Summary
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '200px', textAlign: 'center' }}>
                    Findings
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '200px', textAlign: 'center' }}>
                    Indicator
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ background: '#001823' }}>
                {summaryData &&
                  Object.entries(summaryData)
                    .filter(
                      ([key]) =>
                        key !== 'title' &&
                        key !== 'conclusion' &&
                        key !== 'titleTooltip'
                    )
                    .map(([key, value], index) => (
                      <TableRow key={key}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {value.summary?.split('|').map((part, idx) => (
                            <React.Fragment key={idx}>
                              {idx === 0 ? (
                                <span>
                                  {part.trim()}
                                  {value.toolkit &&
                                    value.toolkit.length > 0 && (
                                      <CustomTooltip
                                        title={
                                          <ul
                                            style={{
                                              margin: 0,
                                              paddingLeft: '20px',
                                            }}
                                          >
                                            {value.toolkit.map(
                                              (item, index) => (
                                                <li key={index}>{item}</li>
                                              )
                                            )}
                                          </ul>
                                        }
                                        sx={{ color: '#000000' }}
                                        arrow
                                      >
                                        <InfoIcon
                                          sx={{
                                            fontSize: 16,
                                            ml: 1,
                                            verticalAlign: 'middle',
                                          }}
                                        />
                                      </CustomTooltip>
                                    )}
                                </span>
                              ) : (
                                <>
                                  <br />
                                  {part.trim()}
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </StyledTableCell>
                        <StyledTableCell>
                          {' '}
                          {value.findings &&
                            value.findings
                              .replace(/&lt;/g, '<')
                              .replace(/&gt;/g, '>')
                              .split('|')
                              .map((part, idx) => (
                                <React.Fragment key={idx}>
                                  {part.trim()}
                                  {idx <
                                    value.findings.split('|').length - 1 && (
                                    <span
                                      style={{ color: 'gray', margin: '0 5px' }}
                                    ></span> // Add a separator
                                  )}
                                  <br />{' '}
                                  {/* Adds a line break after each part */}
                                </React.Fragment>
                              ))}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: 'center' }}>
                          {value.indicator === 'green' ? (
                            <span
                              style={{ color: 'green', fontSize: '2.5rem' }}
                            >
                              ●
                            </span>
                          ) : value.indicator === 'amber' ? (
                            <span
                              style={{ color: '#FFBF00', fontSize: '2.5rem' }}
                            >
                              ●
                            </span>
                          ) : value.indicator === 'red' ? (
                            <span style={{ color: 'red', fontSize: '2.5rem' }}>
                              ●
                            </span>
                          ) : (
                            value.indicator
                          )}
                        </StyledTableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Calculation table with expand/collapse functionality */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body_semibold_20">Calculation</Typography>
            <Button
              variant="text"
              onClick={toggleCalculation}
              startIcon={
                isCalculationExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
              }
            >
              {isCalculationExpanded ? 'Collapse' : 'Expand'}
            </Button>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
              id="calculation-table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '120px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '250px' }}>
                    In {location?.state?.currencyName} '000
                  </HeadTableCell>
                  {location.state?.header?.length &&
                    location.state?.header.map((item, idx) => (
                      <HeadTableCell
                        key={idx}
                        align="right"
                        sx={{ width: '150px' }}
                      >
                        YE {item}
                      </HeadTableCell>
                    ))}
                </TableRow>
              </TableHead>
              {isCalculationExpanded && ( // Conditional rendering of TableBody
                <TableBody
                  sx={{
                    background: '#001823',
                  }}
                >
                  {selectedData.map((row, index) => (
                    <>
                      {row.calculationLabel && (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              borderBottom: 0,
                            },
                          }}
                        >
                          <StyledTableCell
                            align="center"
                            sx={{ width: '100px' }}
                          >
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{
                              width: '200px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row.calculationLabel}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '185px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row?.y5 ? formatNumberWithCommas(row?.y11) : '-'}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '185px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row?.y5 ? formatNumberWithCommas(row?.y5) : '-'}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '185px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row?.y6 ? formatNumberWithCommas(row?.y6) : '-'}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '185px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row?.y14 ? formatNumberWithCommas(row?.y14) : '-'}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '185px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row?.y17 ? formatNumberWithCommas(row?.y17) : '-'}
                          </StyledTableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                  {/* {location.state?.calculation?.y5 && (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': {
                          borderBottom: 0,
                        },
                      }}
                    >
                      <StyledTableCell align="center" sx={{ width: '100px' }}>
                        {selectedData.length + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ width: '200px', padding: '0px 20px !important' }}
                      >
                        Total Capital (shareholders equity)
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        sx={{ width: '185px', padding: '0px 20px !important' }}
                      >
                        {formatNumberWithCommas(
                          location.state?.calculation?.y5
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        sx={{ width: '185px', padding: '0px 20px !important' }}
                      >
                        {formatNumberWithCommas(
                          location.state?.calculation?.y6
                        )}
                      </StyledTableCell>
                    </TableRow>
                  )} */}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>

        {/* Buttons */}
        <Grid item xs={12} my={4} mb={20} sx={{ width: '100%' }}>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            gap={3}
          >
            <AnimateButton>
              <Button
                variant="outlined"
                size="large"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate( '/financial-institution')}
              >
                Prev
              </Button>
            </AnimateButton>
            <AnimateButton>
              <Button
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                onClick={() =>
                  navigate('/financial-loan-breakdown', {
                    state: location.state,
                  })
                }
              >
                Next
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default FiResult;
