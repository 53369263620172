import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  Stack,
  styled,
  StepLabel,
  Stepper,
  Step,
  Grid,
  CircularProgress,
  useTheme,
  IconButton,
} from "@mui/material";
import AnimateButton from "./formStyle/AnimateButton";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as StepIcon } from "../../assets/step.svg";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";

const PageTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_20,

  [theme.breakpoints.down("sm")]: {
    ...theme.typography.Body_semibold_20,
  },
}));

const StepDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_16,
  color: theme.palette.grey[99],
  display: "inline-block",

  [theme.breakpoints.down("sm")]: {
    ...theme.typography.Body_light_14,
  },
}));

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& .MuiStepLabel-iconContainer": {
    paddingRight: theme.spacing(2),
  },
  "& .MuiStepIcon-root": {
    fill: theme.palette.orange.main,
  },
}));

const CustomStepConnector = styled("div")(({ theme }) => ({
  width: 2,
  height: "90%",
  backgroundColor: theme.palette.orange.main,
  position: "absolute",
  left: 11,
  top: 24,
}));

const CustomDialogSterling = ({ open, handleClose }) => {
  const theme = useTheme();

  // const steps = [
  //   <StepDescription>
  //     Download and open Access Bank Application
  //   </StepDescription>,
  //   <StepDescription>
  //     Click on the menu on the Access Bank home screen.
  //   </StepDescription>,
  //   <StepDescription>Click on accounts</StepDescription>,
  //   <StepDescription>Click on share account statements</StepDescription>,
  //   <StepDescription>
  //     Click on “I agree” after going through the terms
  //   </StepDescription>,
  //   <StepDescription>Fills the details required</StepDescription>,
  //   <StepDescription>
  //     Select the third party business (your business in this case){" "}
  //   </StepDescription>,
  //   <StepDescription>Enters pin and send statement </StepDescription>,
  // ];

  return (
    <Dialog open={open}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={1.5}
        >
          <StepIcon />
          <PageTitle>If Error persist then try after 30 Seconds.</PageTitle>
        </Stack>

        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* <Stepper
          activeStep={0}
          orientation="vertical"
          connector={<CustomStepConnector />}
          sx={{ width: '100%', mt: 2, position: 'relative' }}
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <CustomStepLabel>{label}</CustomStepLabel>
            </Step>
          ))}
        </Stepper> */}

        <Grid container sx={{
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           justifyContent: 'center',
        }}>
          <Grid item xs={12}>
            <AnimateButton>
              <LoadingButton
                
                size="large"
                variant="contained"
                sx={{
                  marginTop: '15px',
      
                }}
                onClick={handleClose}
                loadingIndicator={
                  <CircularProgress
                    style={{
                      padding: '60px',
                      color: theme.palette.orange.main,
                    }}
                  />
                }
                // loading={loader}
              >
                Continue
              </LoadingButton>
            </AnimateButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialogSterling;
