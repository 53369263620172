import {
  Autocomplete,
  Box,
  Card,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GetAxiosInstance from "../../components/common/api";
import { getUserData } from "../../components/common/common";
import MainCard from "../../components/common/MainCard";
import {
  BoxWrapper,
  CustomPaper,
  StyledDivider2,
} from "../../components/common/style";
import DashboardUINav from "../../components/Navbar/DashboardUINav/DashboardUINav";
import {
  DirectDebitWrapper,
  RAICard,
  RAICardInfoBox,
  SecondaryCard,
  StyledTableRow,
  StyledTableCellHead,
  StyledTableCellData,
  TableCard,
  StyledTableContainer,
  AmountText,
} from "./RiskAndInsightStyle";
import moment from "moment";
import Loader from "../../components/common/Loader";
import Flutterwave from "../../assets/flutterwave.png";
import Paystack from "../../assets/paystack.png";
import { setMonth } from "date-fns";
import { useLocation } from "react-router-dom";
import { ML_SELECTION_AVAILABLE } from "../../consts/app.const";
import banksList from "../../util/bankList";
import TransactionAmountSpikeChart from "./TransactionAmountSpikeChart";
import StyledCircularProgress from "../../components/common/StyledCircularProgress";
import { useTheme } from "@mui/material/styles";
import { CircularProgressWrapper } from "../dashboard-ui/DashboardUIStyle";

function createData(Item, Percentage, Amount) {
  return { Item, Percentage, Amount };
}
const rows = [
  createData("Restaurant", "23%", "₦ 300,000.00"),
  createData("Telco", "10%", "₦ 180,000.00"),
  createData("Gambling", "10%", "₦ 180,000.00"),
  createData("Overdraft", "10%", "₦ 180,000.00"),
  createData("Loans", "10%", "₦ 180,000.00"),
  createData("Gas", "10%", "₦ 180,000.00"),
  createData("Nightlife", "10%", "₦ 180,000.00"),
  createData("Parties", "10%", "₦ 180,000.00"),
  createData("Hangouts", "10%", "₦ 180,000.00"),
  createData("Trips", "10%", "₦ 180,000.00"),
];

const monthRange = [
  { title: "1 Month", value: 1 },
  {
    title: "3 Months",
    value: 3,
  },
  {
    title: "6 Months",
    value: 6,
  },
  {
    title: "9 Months",
    value: 9,
  },
  {
    title: "12 Months",
    value: 12,
  },
];
const accountIds = [
  {
    title: "0235087159",
    value: null,
  },
  {
    title: "02350871589",
    value: null,
  },
];
const typeOptions = [
  {
    title: "Total",
    value: "tran_amount_total_for_period",
  },
  {
    title: "Monthly Average",
    value: "tran_amount_monthly_average",
  },
];

const mlOptions = [
  {
    title: "Text Based",
    value: false,
  },
  {
    title: "Chat GPT",
    value: true,
  },
];
const IncomeVerificationOverview = () => {
  const theme = useTheme();
  let [data, setData] = useState();
  const location = useLocation();

  let [noSalaryMonths, setNoSalaryMonths] = useState();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [consent, setConsent] = useState();
  const [filletElement, setFilletElement] = useState([]);

  const [endDate, setEndDate] = useState();
  const [totalIncome, setTotalIncome] = useState([]);
  const [totalExpense, setTotalExpense] = useState([]);
  const [totalAffordability, setTotalAffordability] = useState([]);
  const [totalInFlow, setTotalInFlow] = useState([]);
  const [totalOutFlow, setTotalOutFlow] = useState([]);
  const [riskIndicators, setRiskIndicators] = useState([]);
  const [selectMonth, setSelectMonth] = useState();
  const [openExpenses, setOpenExpenses] = useState(false);
  const [riskResponse, setRiskResponse] = useState({});
  const [accountsNumberList, setAccountsNumberList] = useState();
  const [selectAccount, setSelectAccount] = useState(null);
  const [grantTotal, setGrantTotal] = useState();
  const [dateToValueData, setDateToValueData] = useState("");
  const [selectedML, setSelectedML] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [selectedBankLogo, setSelectedBankLogo] = useState();
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedType, setSelectedType] = useState(
    "tran_amount_total_for_period"
  );
  const [selectedDateRang, setSelectedDateRang] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [dashboardData, setDashboardData] = useState([]);
  const [selectedConsent, setSelectedConsent] = useState({});

  const handleClickOpenExpensesDialog = () => {
    setOpenExpenses(true);
  };
  const handleCloseExpensesDialog = () => {
    setOpenExpenses(false);
  };

  const [openAffordability, setOpenAffordability] = useState(false);
  const handleClickOpenAffordabilityDialog = () => {
    setOpenAffordability(true);
  };
  const handleCloseAffordabilityDialog = () => {
    setOpenAffordability(false);
  };
  useEffect(async () => {
    setBankList(await banksList());
  }, []);
  useEffect(async () => {
    if (bankList.length) {
      setLoader(true);
      let userData = getUserData();
      await GetAxiosInstance(`orchestrator/get-consent`, "get").then((res) => {
        if (res.data?.codes === 200) {
          if (res.data?.data?.consents?.length) {
            let accountNumberList = [];
            res?.data?.data?.consents.map((v) => {
              if (
                v.consent.data.products.filter(
                  (item) =>
                    item.code === "affordability_basic" ||
                    item.code === "affordability_full"
                ).length
              )
                if (v.accountNumber && v.dashboardAvailable) {
                  accountNumberList.push({
                    title: `XXXXXX${v.accountNumber.slice(-4)}`,
                    value: v.accountNumber,
                    icon: bankList.find((i) => i.bankName === v.bankName)?.icon,
                    bankName: bankList.find((i) => i.bankName === v.bankName)
                      ?.bankName,
                  });
                }
            });
            if (!accountNumberList.length) {
              navigator("/statement");
            }
            accountNumberList.push({
              title: "CONSOLIDATED",
              value: "all",
              icon: "",
              bankName: "",
            });
            setAccountsNumberList(accountNumberList);
            setConsent(res.data?.data?.consents);
          }
        } else {
          setLoader(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              "Something went wrong!"
          );
        }
      });
    }
  }, [bankList]);
  useEffect(() => {
    setLoader(true);
    let endDate = moment().format("YYYY-MM-DD ");

    let selectedProduct = consent?.find(
      (item) => item.accountNumber === selectAccount
    );
    if (selectedProduct && selectAccount?.length) {
      endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
        "YYYY-MM-DD"
      );
      let dateRangArray = [
        {
          startDate: selectedProduct?.consent?.data?.dateFrom,
          endDate: selectedProduct?.consent?.data?.dateTo,
        },
      ];
      calculateDateRang(dateRangArray, setSelectedDateRang);
      setSelectedConsent(selectedProduct);
    } else {
      let dateRangArray = consent?.map((v) => {
        return {
          startDate: v.consent?.data?.dateFrom,
          endDate: v.consent?.data?.dateTo,
        };
      });
      let allEndDate = consent?.map((v) => v.consent?.data?.dateTo);
      let maxEndDate =
        allEndDate?.length &&
        allEndDate.reduce(function (a, b) {
          return a > b ? a : b;
        });
      calculateDateRang(dateRangArray, setSelectedDateRang);
      endDate = moment(maxEndDate).format("YYYY-MM-DD");
      setEndDate(endDate);
    }
  }, [selectAccount, consent, selectMonth]);
  useEffect(() => {
    let noSalaryMonths = "";
    riskResponse?.noincome?.length &&
      riskResponse?.noincome.forEach((item, index) => {
        let separateBy = "";
        if (index === 0) {
          separateBy = "";
        } else if (index === data?.noincome.length - 1) {
          separateBy = " and ";
        } else {
          separateBy = ", ";
        }
        noSalaryMonths =
          noSalaryMonths + separateBy + moment(item.tmonth).format("MMMM");
      });
    setNoSalaryMonths(noSalaryMonths);
  }, [riskResponse]);
  useEffect(() => {
    let dataArray = [];
    let userData = getUserData();

    let data = selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    setFilletElement(dataArray);
    let dateToValue = selectMonth
      ? [...filletElement, ...selectedDateRang].find((item) => {
          if (selectedEndDate) {
            return item.value === selectedEndDate;
          } else {
            return item.value === selectMonth;
          }
        })
      : filletElement[filletElement.length - 1];
    setDateToValueData(dateToValue);
    let dashboardPayload = {
      userId: userData?.id,
      date_to:
        moment(dateToValue?.title).format("MMMM - YYYY") !== "Invalid date"
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format("MMMM - YYYY") !== "Invalid date"
          ? 1
          : selectMonth || dataArray[dataArray.length - 1]?.value,
      account_id: selectAccount === "all" ? null : selectAccount,
      testMLModel: selectedML,
      accountType: selectedConsent?.accountType,
      consentId: selectedConsent?.consentId,
    };

    if (
      dashboardPayload?.userId &&
      dashboardPayload?.date_to &&
      dashboardPayload?.n_months
    ) {
      GetAxiosInstance(
        "orchestrator/get-dashboard",
        "post",
        dashboardPayload
      ).then((res) => {
        if (res?.data?.codes === 200) {
          // setLoader(false);
          // if (res?.data?.data?.monthly?.length) {
          setDashboardData(res?.data?.data?.monthly);
          // } else {
          // setLoader(false);
          // }
        } else {
          setDashboardData([]);
          setLoader(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    }
    let payload = {
      user_id: userData?.id,
      date_to:
        moment(dateToValue?.title).format("MMMM - YYYY") !== "Invalid date"
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format("MMMM - YYYY") !== "Invalid date"
          ? 1
          : selectMonth || dataArray[dataArray.length - 1]?.value,
      account_id: selectAccount === "all" ? null : selectAccount,
      tran_threshold: 0,
      testMLModel: selectedML,
      accountType: selectedConsent?.accountType,
      consentId: selectedConsent?.consentId,
    };
    if (payload.user_id && payload.date_to && payload.n_months) {
      GetAxiosInstance("orchestrator/get-risk", "post", payload).then((res) => {
        if (res?.data?.codes === 200) {
          setLoader(false);
          const result = res.data?.data?.transaction_threshold.reduce(
            (total, thing) => total + thing[selectedType],
            0
          );
          setGrantTotal(result);
          setRiskResponse(res.data?.data);
        } else {
          setRiskResponse([]);
          setGrantTotal();
          setLoader(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    }
  }, [selectedDateRang, endDate, selectedML]);
  useEffect(() => {
    const maxObject =
      consent &&
      consent.length &&
      consent.reduce((max, currentObject) => {
        if (
          currentObject.id > max.id &&
          currentObject?.dashboardAvailable &&
          currentObject?.consent?.data?.products.filter(
            (x) => x.code === "affordability_full"
          ).length
        ) {
          return currentObject;
        }
        return max;
      }, consent[0]);
    if (maxObject && selectAccount !== "all" && !selectAccount) {
      setSelectAccount(maxObject?.accountNumber);
      setSelectedAccountType(maxObject?.accountType);

      if (bankList.length) {
        const selectedIcon = bankList.find(
          (i) => maxObject?.bankName === i.bankName
        );
        console.log("selectedIcon", selectedIcon);
        setSelectedBankLogo(selectedIcon?.icon);
      }
    }
  }, [consent, bankList]);
  return (
    <>
      <DashboardUINav dashboardData={dashboardData} consent={consent} />
      <BoxWrapper mt={5}>
        {!loader ? (
          <>
            <Box sx={{ flexGrow: 1, mt: 10 }}>
              <Grid container spacing={3}>
                <Grid item md={3} sm={12} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={11}>
                      <RAICard>
                        <Stack
                          direction="row"
                          alignItems="center"
                          mb={3}
                          spacing={1}
                        >
                          <Typography
                            variant="Body_medium_28"
                            color="orange.main"
                          >
                            Verification Score
                          </Typography>
                        </Stack>
                        <Stack direction="column">
                          <Typography variant="Body_light_14">
                            The verification score is determined based on our
                            analysis of account activity.
                          </Typography>
                        </Stack>
                        <CircularProgressWrapper
                          sx={{
                            width: "auto",
                            cursor: "arrow",
                            marginTop: "60px",
                          }}
                        >
                          <StyledCircularProgress
                            progressValue={99}
                            indicatorColor={theme.palette.success.main}
                            type="percentage"
                            size="130"
                          />
                        </CircularProgressWrapper>
                      </RAICard>
                    </Grid>

                    <Grid item xs={11}>
                      <SecondaryCard>
                        <Stack
                          alignItems="flex-start"
                          direction="column"
                          justifyContent="space-between"
                          gap={2}
                        >
                          <Typography
                            variant="Body_medium_28"
                            color="orange.main"
                          >
                            Top Payer
                          </Typography>

                          <Stack direction="column">
                            <Typography variant="Body_light_14">
                              Below is the list of individuals who consistently
                              make payments to this account.
                            </Typography>
                          </Stack>
                          <Stack direction="column">
                            <Typography variant="Body_medium_20">
                             1. Tunde Ogundipe
                            </Typography>
                          </Stack>
                        </Stack>
                      </SecondaryCard>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={9} sm={12} xs={12}>
                  <TransactionAmountSpikeChart
                    risk_spikes={riskResponse?.risk_spikes}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </BoxWrapper>
    </>
  );
};
function calculateDateRang(allDateRange, setSelectedDateRang) {
  var result = [];
  allDateRange?.forEach((v) => {
    var startDate = moment(v.startDate);
    var endDate = moment(v.endDate);
    if (endDate.isBefore(startDate)) {
      return;
    }
    while (startDate.isBefore(endDate)) {
      let lastDate = moment(startDate).endOf("month").format("YYYY-MM-DD");

      let diff = moment(endDate).diff(moment(lastDate), "days");
      if (diff < 0) {
        lastDate = moment(endDate).format("YYYY-MM-DD");
      }

      result.push(lastDate);
      startDate.add(1, "month");
    }
    result
      .sort(
        (a, b) =>
          new moment(a).format("YYYYMMDD") - new moment(b).format("YYYYMMDD")
      )
      .reverse();
    function filterUniqueDates(data) {
      const lookup = new Set();
      return data.filter((date) => {
        const serialised = new Date(date).getTime();

        if (lookup.has(serialised)) {
          return false;
        } else {
          lookup.add(serialised);
          return true;
        }
      });
    }
    let uniqueDates = filterUniqueDates(result);
    uniqueDates =
      uniqueDates.length &&
      uniqueDates.map((i) => {
        return {
          title: i,
          value: i,
        };
      });
    setSelectedDateRang(uniqueDates);
  });
}
export default IncomeVerificationOverview;
