import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { getUserData, removeUserData } from '../../components/common/common';
import AnimateButton from '../../components/common/formStyle/AnimateButton';
import InputLabel from '../../components/common/formStyle/InputLabel';
import {
  ContentStyle,
  FormInfoBox,
  FormInputWrapper,
  FormStyle,
  FromWrapper,
  RootStyle,
  StyledTypography,
} from '../Auth/AuthStyle';

// ----------------------------------------------------------------------

export function ExternalLogin() {
  let navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState();
  const formSchema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    let existsUSerData = getUserData();
    if (existsUSerData) {
      removeUserData();
    }
    localStorage.setItem('isExternalLogin', false);
  }, []);
  const checkLogin = (data) => {
    const credentials = [
      { email: 'admin@gmail.com', password: '12345678' },
      { email: 'nigeria.admin@gmail.com', password: '12345678' }
    ];
  
    const isValidUser = credentials.some(
      (cred) => data.email === cred.email && data.password === cred.password
    );
  
    if (isValidUser) {
      localStorage.setItem('email', data.email);
      if (data.email === 'nigeria.admin@gmail.com') {
        navigate('/financial-institution-nigeria');
      } else {
        navigate('/financial-institution');
      }
      localStorage.setItem('isExternalLogin', true);
    } else {
      toast.error('Invalid email or password');
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);
    await checkLogin(data);
    setLoading(false);
  };
  return (
    <>
      <RootStyle>
        <FromWrapper
          sx={{
            height: { xs: '70vh' },
          }}
        >
          <ContentStyle>
            <FormStyle
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack>
                <FormInfoBox>
                  <StyledTypography variant="Body_semibold_20">
                    Welcome back!
                  </StyledTypography>
                  <Typography variant="Body_semibold_24">
                    Please Log in...
                  </Typography>
                </FormInfoBox>
                {/* <form> */}
                <FormInputWrapper>
                  <Grid item xs={12}>
                    <InputLabel>Email Address</InputLabel>
                    <TextField
                      variant="filled"
                      type="email"
                      placeholder="Enter email address"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      hiddenLabel
                      {...register('email')}
                      helperText={errors.email && errors.email.message}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>Enter Password</InputLabel>
                    <TextField
                      variant="filled"
                      type="password"
                      placeholder="Enter your password"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      hiddenLabel
                      {...register('password')}
                      helperText={errors.password && errors.password.message}
                    />
                  </Grid>
                </FormInputWrapper>

                <AnimateButton>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ width: '100%' }}
                    loadingIndicator={
                      <CircularProgress
                        style={{
                          padding: '60px',
                          color: theme.palette.orange.main,
                        }}
                      />
                    }
                    loading={loading}
                  >
                    Login
                  </LoadingButton>
                </AnimateButton>
              </Stack>
            </FormStyle>
          </ContentStyle>
        </FromWrapper>
      </RootStyle>
    </>
  );
}
