import {
  Autocomplete,
  Box,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CustomPaper } from '../../components/common/style';
import React from 'react';
import { BpRadio } from '../bank-selection/BankSelectionStyle';
const ExpandMore1 = styled((props) => {
  const { expand1, ...other } = props;
  return <IconButton {...other} expand={expand1?.toString()} />;
})(({ theme, expand }) => ({
  transform: expand === 'true' ? 'rotate(0deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
export const BankInformation = (props) => {
  const theme = useTheme();
  console.log('props', props);

  return (
    <Box sx={{ width: '100%' }}>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="Body_medium_20" color="orange.main">
          {/* Select your Bank */}
        </Typography>
      </CardContent>
      {props?.productName !== 'bvn' && (
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Grid container gap={4} alignItems="flex-start">
            <Grid item md={7} sm={4}>
              <Typography
                className="account-information-label"
                variant="Body_medium_16"
                color="orange.main"
              >
                Account Number
              </Typography>
              <TextField
                id="filled-hidden-label-normal"
                hiddenLabel
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                variant="filled"
                placeholder="Enter your account number"
                autoComplete="off"
                InputLabelProps={{
                  shrink: true,
                }}
                value={props?.accountDetails?.number || ''}
                helperText={
                  props?.accountNumberError && props?.accountNumberError
                }
                onChange={props?.handleAccountNumberChange}
              />

              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                mt={2}
              >
                <Typography variant="Body_medium_14" color="grey.light">
                  {props?.accountDetails?.accountName}
                </Typography>

                <Typography variant="Body_medium_14" color="orange.main">
                  Verified account details
                </Typography>
              </Stack>
            </Grid>

            <Grid item md={4} sm={4}>
              <Typography
                className="account-information-label"
                variant="Body_medium_16"
                color="orange.main"
              >
                Bank
              </Typography>
              <Autocomplete
                id="select-product"
                options={props?.bankList}
                disableClearable
                getOptionDisabled={(option) => !option?.enabled}
                getOptionLabel={(option) => option.displayName}
                value={
                  props?.bankList?.length &&
                  props?.bankList.find(
                    (item) => item?.bankName === props?.accountDetails.bankName
                  )
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    key={option.bankName}
                    name={option.bankName}
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      name={option.bankName}
                      width="48"
                      style={{ borderRadius: '50%' }}
                      src={option.icon}
                      alt=""
                    />
                    {option.displayName}
                  </Box>
                )}
                PaperComponent={CustomPaper}
                onChange={props?.handleBankChange}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    id="filled-hidden-label-normal"
                    placeholder="Select..."
                    hiddenLabel
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {props?.selectedBankLogo && (
                            <Box
                              component="img"
                              src={props?.selectedBankLogo}
                              alt=""
                              sx={{
                                width: '48px',
                                height: '48px',
                                borderRadius: '50%',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )}
              />
              <Box
                component={'div'}
                style={{
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  fontSize: '.75rem',
                  lineHeight: '1.66',
                  textAlign: 'left',
                  margin: '3px 14px 0 14px',
                  color: 'red',
                }}
              >
                {props?.bankSelectError && props?.bankSelectError}
              </Box>
            </Grid>
            <Grid item md={4} sm={4}>
              <Typography
                className="account-information-label"
                variant="Body_medium_16"
                color="orange.main"
              >
                Account Type
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={props?.accountType}
                  onClick={props?.handleCheck}
                >
                  <Stack direction={'row'} width={'max-content'}>
                    <FormControlLabel
                      value="Personal"
                      control={<BpRadio />}
                      labelPlacement="end"
                      label="Personal Account"
                    />
                    <FormControlLabel
                      value="Business"
                      control={<BpRadio />}
                      labelPlacement="end"
                      label="Business Account"
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
              <Box
                component="div"
                style={{
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  fontSize: '.75rem',
                  lineHeight: '1.66',
                  textAlign: 'left',
                  margin: '3px 14px 0 14px',
                  color: 'red',
                }}
              >
                {props?.accountTypeError && props?.accountTypeError}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      )}
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Grid container gap={4} alignItems="flex-start">
          <Grid item md={7} sm={4}>
            <Typography
              className="account-information-label"
              variant="Body_medium_16"
              color="orange.main"
            >
              Business Partner Name
            </Typography>
            <Autocomplete
              id="select-business-partner"
              options={props?.partnerList || []}
              disableClearable
              getOptionDisabled={(option) =>
                localStorage.getItem('deepLinkId') &&
                option.uniqueId !== localStorage.getItem('deepLinkId')
                  ? true
                  : false
              }
              getOptionLabel={(option) => option.companyName || ''}
              PaperComponent={props?.CustomPaper}
              value={
                props?.partnerList?.length &&
                props?.partnerList.find((item) => item?.id === props?.partnerId)
              }
              renderInput={(params) => (
                <TextField
                  variant="filled"
                  id="filled-hidden-label-normal"
                  placeholder="Select..."
                  inputProps={{ 'aria-label': 'Without label' }}
                  hiddenLabel
                  // fullWidth
                  {...params}
                />
              )}
              onChange={(event, value) => {
                if (value) {
                  props?.setPartnerId(value.id);
                }
              }}
            />
            <Box
              component="div"
              style={{
                fontFamily: 'Inter',
                fontWeight: '400',
                fontSize: '.75rem',
                lineHeight: '1.66',
                textAlign: 'left',
                margin: '3px 14px 0 14px',
                color: 'red',
              }}
            >
              {props?.selectBusinessPartnerError &&
                props?.selectBusinessPartnerError}
            </Box>
          </Grid>
          {props?.productName !== 'bvn' && (
            <Grid item md={4} sm={5}>
              <Typography
                className="account-information-label"
                variant="Body_medium_16"
                color="orange.main"
              >
                Time of Period
              </Typography>
              <FormControl
                sx={{ width: '100%' }}
                variant="filled"
                hiddenLabel
                value={props?.monthCount}
                onChange={props?.handleSelectedMonth}
              >
                <Select
                  placeholder="Select..."
                  variant="filled"
                  hiddenLabel
                  value={props?.monthCount}
                  onChange={props?.handleSelectedMonth}
                >
                  {props?.monthRange.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Box>
  );
};
