import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AnimateButton from '../../components/common/formStyle/AnimateButton';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Styled table cell components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #000',
  fontSize: '0.9rem',
}));

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #000',
  backgroundColor: '#132933',
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const SectionHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#132933',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  textAlign: 'center',
  borderBottom: 'none',
}));

const InstructionCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#132933',
  fontSize: '0.85rem',
  textAlign: 'center',
  color: theme.palette.common.white,
  fontStyle: 'italic',
}));

const DirectorsTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [executiveData, setExecutiveData] = useState([]);
  const [boardData, setBoardData] = useState([]);
  useEffect(() => {
    if (state) {
      setExecutiveData(state?.executiveData || []);
      setBoardData(state?.boardOfDirectorsData || []);
    }
  }, [state]);
  return (
    <Grid container spacing={2} mt={5}>
      <Stack>
        <Box display="flex" mt={2} gap={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Country :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedCountry?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {location.state?.selectedBank?.country.charAt(0).toUpperCase() + location.state?.selectedBank?.country.slice(1)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Bank :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedBank?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {location.state?.selectedBank?.bankName}
            </Typography>
          </Box>
        </Box>
      </Stack>
      {/* Board of Directors Table */}
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden', mb: 4 }}
        >
          <Table aria-label="board of directors table">
            <TableHead>
              <TableRow>
                <SectionHeaderCell colSpan={5}>
                  Board of Directors
                </SectionHeaderCell>
              </TableRow>
              <TableRow>
                <HeadTableCell sx={{ width: '120px' }}>Name</HeadTableCell>
                <HeadTableCell sx={{ width: '160px' }}>
                  Board Position
                </HeadTableCell>
                <HeadTableCell sx={{ width: '120px' }}>
                  Board Member since (dd/mm/yy)
                </HeadTableCell>
                <HeadTableCell sx={{ width: '300px' }}>
                  Other Current / Previous Positions
                </HeadTableCell>
                <HeadTableCell sx={{ width: '300px' }}>
                  Education / Other Qualification
                </HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {boardData.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell sx={{ width: '120px' }}>
                    {row.name || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '160px' }}>
                    {row.position || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '120px' }}>
                    {row.memberSince  || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '300px' }}>
                    {row.previousPosition || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '300px' }}>
                    {row.education || '-'}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Executive Management Table */}
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="executive management table">
            <TableHead>
              <TableRow>
                <SectionHeaderCell colSpan={5}>
                  Executive Management
                </SectionHeaderCell>
              </TableRow>
              <TableRow>
                <HeadTableCell sx={{ width: '120px' }}>Name</HeadTableCell>
                <HeadTableCell sx={{ width: '160px' }}>Position</HeadTableCell>
                <HeadTableCell sx={{ width: '120px' }}>
                  Start Date of Current Position (dd/mm/yy)
                </HeadTableCell>
                <HeadTableCell sx={{ width: '300px' }}>
                  Previous Executive / Senior Management Positions
                </HeadTableCell>
                <HeadTableCell sx={{ width: '300px' }}>
                  Education / Other Qualification
                </HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {executiveData.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell sx={{ width: '130px' }}>
                    {row.name || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '160px' }}>
                    {row.position || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '120px' }}>
                    {row.startDate || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '300px' }}>
                    {row.previousPosition || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '300px' }}>
                    {row.education  || '-'}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} my={4} sx={{ width: '100%' }}>
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={3}
        >
          <AnimateButton>
            <Button
              variant="outlined"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                navigate('/financial-loan-breakdown', { state: location.state })
              }
            >
              Prev
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              // endIcon={<ArrowForwardIcon />}
            >
              Done
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DirectorsTable;
