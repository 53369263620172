import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimateButton from '../../../components/common/formStyle/AnimateButton';

import PolarisBank from '../../../assets/bankDetails/nigerian/Polaris_OO.json';
import AccessBank from '../../../assets/bankDetails/nigerian/AccessBank_OO.json';
import AccionBank from '../../../assets/bankDetails/nigerian/AccionBank_OO.json';
import FCMB from '../../../assets/bankDetails/nigerian/FCMB_OO.json';
import FirstBank from '../../../assets/bankDetails/nigerian/FirstBank_OO.json';
// Import the image directly

import nigeria from '../../../assets/nigeria.png';


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

const selectCountry = [
  {
    bankName: 'nigeria',
    displayName: 'Nigeria',
    icon: nigeria,
    currencySymbol: '$',
    currencyName: 'Naira',
    enabled: true,
    region: 'west-africa',
  }
];

const selectRegion = [
 
  { displayName: 'WEST AFRICA', value: 'west-africa' },
  
];

const tableRows = [
  {
    id: 'a1',
    label: 'Gross Loans',
  },
  {
    id: 'a2',
    label: 'Non-Performing Loans',
  },
  {
    id: 'a3',
    label: 'Provision for Loan Loss ',
  },
  {
    id: 'a4',
    label: 'Tier 1 Capital',
  },
  {
    id: 'a5',
    label: 'Tier 2 Capital',
  },
  {
    id: 'a6',
    label: 'Total Capital',
  },
  {
    id: 'a7',
    label: 'Total Risk-weighted Assets',
  },
  {
    id: 'a8',
    label: 'Tier 1 Capital Ratio %',
  },
  {
    id: 'a9',
    label: 'Top 20 loans Concentration level %',
  },
  {
    id: 'a10',
    label: 'Top 20 depositors concentration level %',
  },
  {
    id: 'a11',
    label: '',
  },
];

const FinancialInstitutionNigeria = () => {
  const navigate = useNavigate();
  const [bankList, setBankList] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State for select all
  const [selectedBankLogo, setSelectedBankLogo] = useState();
  const [selectedBank, setSelectedBank] = useState([]);
  const [selectedCountryLogo, setSelectedCountryLogo] = useState(null);
  const [filteredBanks, setFilteredBanks] = useState([]); // State for filtered banks
  const [currencySymbol, setCurrencySymbol] = useState(''); // Default currency symbol
  const [currencyName, setCurrencyName] = useState(''); // Default currency name
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null); // Add this line
  useEffect(() => {
    // Combine all banks into a single array
    const bankList = [
      PolarisBank,
      AccessBank,
      AccionBank,
      FCMB,
      FirstBank,
    ];
    const flattenedBankList = bankList.flat(); // Use this if JSONs contain arrays
    setBankList(flattenedBankList);
  }, []);

  useEffect(() => {
    if (bankList?.length) {
      setSelectedBankLogo(bankList[0]?.icon);
      setSelectedBank(bankList[0]);
    }
  }, [bankList]);

  // Handle row selection for checkboxes
  const handleRowClick = (index) => {
    setCheckedRows((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const isChecked = (index) => checkedRows.includes(index);

  // Handle Select All checkbox
  const handleSelectAllClick = () => {
    if (selectAll) {
      // Uncheck all rows
      setCheckedRows([]);
    } else {
      // Select all rows
      setCheckedRows(tableRows.map((_, index) => index));
    }
    setSelectAll(!selectAll);
  };

  const handleRegionChange = (event, value) => {
    if (!value) {
      setFilteredCountries([]);
      setSelectedCountryLogo(null);
      setCurrencySymbol('');
      setCurrencyName('');
      setSelectedBank(null);
      setSelectedBankLogo(null);
      setFilteredBanks([]);
      setSelectedCountry(null);
      return;
    }

    // Filter countries based on the selected region
    const filtered = selectCountry.filter(
      (country) => country.region === value.value
    );
    setFilteredCountries(filtered);
    setSelectedCountryLogo(null);
    setCurrencySymbol('');
    setCurrencyName('');
    setSelectedBank(null);
    setSelectedBankLogo(null);
    setFilteredBanks([]);
    setSelectedCountry(null); // Reset selected country
  };

  // Filter banks based on selected country
  const handleCountryChange = (e, value) => {
    if (!value) {
      setSelectedCountryLogo(null);
      setCurrencySymbol('');
      setCurrencyName('');
      setFilteredBanks([]);
      setSelectedBank(null);
      setSelectedBankLogo(null);
      setSelectedCountry(null); // Reset selected country
      return;
    }

    setSelectedCountryLogo(value.icon);
  setCurrencySymbol(value.currencySymbol); // Set the selected country's currency symbol
  setCurrencyName(value.currencyName); // Set the selected country's currency name
  setSelectedCountry(value); // Set the selected country
  // Filter banks based on the country selected
  const filtered = bankList.filter((bank) => bank.country === value.bankName);
  setFilteredBanks(filtered);
  setSelectedBank(null); // Reset selected bank
  setSelectedBankLogo(null); // Reset selected bank logo
  };

  // Get selected data from the table rows
  const selectedData = tableRows.filter((_, index) =>
    checkedRows.includes(index)
  );

  // Handle Next button click and pass selected data and currency info to the next route
  const handleNextClick = () => {
    let selectedCountry = selectCountry.find(
      (country) => country.icon === selectedCountryLogo
    );
  
    if (!selectedCountry || !selectedBank) {
      console.error('Selected country or bank is undefined');
      return;
    }
  
    const allTable = bankList.find((i) => i.bankName === selectedBank.bankName);
    const data = selectedData.map((i) => {
      return {
        ...i,
        ...(allTable?.financialData?.length &&
          allTable?.financialData.find((j) => j.id === i.id)),
      };
    });
  
    navigate('/financial-result-ng', {
      state: {
        selectedData: data,
        header: selectedBank?.header,
        name: selectedBank?.bankName,
        currencySymbol: selectedCountry.currencySymbol,
        currencyName: selectedCountry.currencyName,
        selectedCountry,
        selectedBank,
        calculation:
          allTable?.financialData[allTable?.financialData?.length - 1],
        summaryData: selectedBank.summaryData,
        breakDown2020Data: selectedBank.breakDown2020Data || [],
        breakDown2021Data: selectedBank.breakDown2021Data || [],
        breakDown2022Data: selectedBank.breakDown2022Data || [],
        breakDown2023Data: selectedBank.breakDown2023Data || [],
        breakDown2024Data: selectedBank.breakDown2024Data || [],
        boardOfDirectorsData: selectedBank.boardOfDirectorsData,
        executiveData: selectedBank.executiveData,
        docUrl: selectedBank.docUrl,
        reportUrl: selectedBank.reportUrl,
      },
    });
  };

  // Set default region and country on component load

  useEffect(() => {
    const defaultRegion = selectRegion.find(
      (region) => region.value === 'west-africa'
    );
    const filtered = selectCountry.filter(
      (country) => country.region === defaultRegion.value
    );
    setFilteredCountries(filtered);
  
    const defaultCountry = selectCountry.find(
      (country) => country.bankName === 'nigeria'
    );
    if (defaultCountry) {
      setSelectedCountry(defaultCountry);
      setSelectedCountryLogo(defaultCountry.icon);
      setCurrencySymbol(defaultCountry.currencySymbol);
      setCurrencyName(defaultCountry.currencyName);
      const filteredBanks = bankList.filter(
        (bank) => bank.country === defaultCountry.bankName
      );
      if (filteredBanks.length > 0) {
        setSelectedBank(filteredBanks[0]);
        setSelectedBankLogo(filteredBanks[0].icon);
      }
      // Set filteredBanks to include all banks
      setFilteredBanks(filteredBanks);
    }
  }, [bankList]);

  return (
    <Box>
      <Grid
        container
        my={3}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        textAlign={'center'}
        spacing={3}
      >
        <Grid item xs={12}>
          <Stack flexDirection={'column'} justifyContent={'space-between'}>
            <Typography
              mb={1}
              variant="Body_medium_16"
              color="common.white"
              textAlign={'start'}
            >
              Select Region
            </Typography>
            <Grid item sx={{ width: '300px' }}>
              <Autocomplete
                id="select-region"
                options={selectRegion}
                disableClearable
                defaultValue={selectRegion[0]}
                getOptionLabel={(option) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    id="filled-hidden-label-normal"
                    placeholder="Select Region"
                    hiddenLabel
                    {...params}
                    fullWidth
                  />
                )}
                onChange={handleRegionChange}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Stack>
        </Grid>
        {/* Select Country Section */}
        <Grid item xs={12}>
          <Stack flexDirection={'column'} justifyContent={'space-between'}>
            <Typography
              mb={1}
              variant="Body_medium_16"
              color="common.white"
              textAlign={'start'}
            >
              Select Country
            </Typography>
            <Grid item sx={{ width: '300px' }}>
              <Autocomplete
                id="select-country"
                options={filteredCountries}
                disableClearable
                value={selectedCountry}
                getOptionDisabled={(option) => !option?.enabled}
                getOptionLabel={(option) => option.displayName || ''}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    name={option.bankName}
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      name={option.bankName}
                      width="48"
                      height="48" // Ensure width and height are the same
                      style={{ borderRadius: '50%' }} // This ensures the image is round
                      src={option.icon}
                      alt={option.displayName}
                    />
                    {option.displayName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    id="filled-hidden-label-normal"
                    placeholder="Select Country"
                    hiddenLabel
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {selectedCountryLogo && (
                            <Box
                              component="img"
                              src={selectedCountryLogo}
                              alt=""
                              sx={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )}
                onChange={handleCountryChange}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Stack>
        </Grid>

        {/* Select Bank Section */}
        <Grid item xs={12}>
          <Typography variant="Header_semibold_48">
            Select Financial Institution
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Stack flexDirection={'column'} justifyContent={'space-between'}>
            <Typography
              mb={1}
              variant="Body_medium_16"
              color="common.white"
              textAlign={'start'}
            >
              Select Bank
            </Typography>
            <Grid item sx={{ width: '300px' }}>
              {console.log('banklist',filteredBanks)}
              <Autocomplete
                id="select-bank"
                value={selectedBank || null} // Default to Nigeria
                options={filteredBanks} // Use filtered banks based on the country selected
                disableClearable
                getOptionDisabled={(option) => !option?.enabled}
                getOptionLabel={(option) => option.displayName || ''}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    name={option.bankName}
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      name={option.bankName}
                      width="48"
                      style={{ borderRadius: '50%' }}
                      src={`/${option.icon}`}
                      alt={option.displayName}
                    />
                    {option.displayName}
                  </Box>
                )}
                renderInput={(params) => (
                  <>
                    <TextField
                      variant="filled"
                      id="filled-hidden-label-normal"
                      placeholder="Select Bank"
                      hiddenLabel
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {selectedBankLogo && (
                              <Box
                                component="img"
                                src={`/${selectedBankLogo}`}
                                alt=""
                                sx={{
                                  width: '35px',
                                  height: '35px',
                                  borderRadius: '50%',
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </>
                )}
                onChange={(e, value) => {
                  setSelectedBankLogo(value.icon); // Dynamically set the bank logo based on selection
                  setSelectedBank(value); // Dynamically set the bank logo based on selection
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Stack>
        </Grid>

        {/* Table Section */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center">
                    Select All
                    <Checkbox
                      checked={selectAll}
                      indeterminate={
                        checkedRows.length > 0 &&
                        checkedRows.length < tableRows.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </HeadTableCell>
                  <HeadTableCell>In {currencyName} '000 </HeadTableCell>{' '}
                  {selectedBank?.header?.length &&
                    selectedBank?.header.map((item) => (
                      <>
                        <HeadTableCell align="right">{item}</HeadTableCell>{' '}
                      </>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  background: '#001823',
                }}
              >
                {tableRows.map((row, index) => (
                  <>
                    {row?.label && (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                          },
                          cursor: 'pointer',
                          background: isChecked(index) ? '#042331' : 'inherit',
                        }}
                        onClick={() => handleRowClick(index)}
                      >
                        <StyledTableCell align="center">
                          <Checkbox
                            checked={isChecked(index)}
                            onChange={() => handleRowClick(index)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.label}
                        </StyledTableCell>

                        {selectedBank?.header?.length &&
                          selectedBank?.header.includes(2020) && (
                            <StyledTableCell align="right">
                              ------
                            </StyledTableCell>
                          )}

                        {selectedBank?.header?.length &&
                          selectedBank?.header.includes(2021) && (
                            <StyledTableCell align="right">
                              ------
                            </StyledTableCell>
                          )}
                        {selectedBank?.header?.length &&
                          selectedBank?.header.includes(2022) && (
                            <StyledTableCell align="right">
                              ------
                            </StyledTableCell>
                          )}
                        {selectedBank?.header?.length &&
                          selectedBank?.header.includes(2023) && (
                            <StyledTableCell align="right">
                              ------
                            </StyledTableCell>
                          )}
                        {selectedBank?.header?.length &&
                          selectedBank?.header.includes(2024) && (
                            <StyledTableCell align="right">
                              ------
                            </StyledTableCell>
                          )}
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={handleNextClick}
              disabled={!selectedData?.length}
              sx={{
                '&.Mui-disabled': {
                  backgroundColor: 'danger.light', // Change background color when disabled
                  color: 'white', // Change text color when disabled
                },
              }}
            >
              Next
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialInstitutionNigeria;
