import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getUserData } from '../../components/common/common';

import { CustomPaper, StyledDivider2 } from '../../components/common/style';

import DashboardUINav from '../../components/Navbar/DashboardUINav/DashboardUINav';
import {
  MainCardStyled,
  OverviewWrapper,
  PageBoxWrapper,
  TableCellData,
  TableCellHead,
} from './StatementStyle';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'react-toastify';
import GetAxiosInstance from '../../components/common/api';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import banksList from '../../util/bankList';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function createData(Item) {
  return { Item };
}
const rows = [
  createData('Basic Statements '),
  createData('Statement Plus '),
  createData('Real Time Balance '),
  createData('Real Time Balance '),
  createData('Full Affordability Report '),
];

const Statement = () => {
  let userData = getUserData();
  const theme = useTheme();
  const navigator = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const [consent, setConsent] = useState();
  const [selectAccount, setSelectAccount] = useState();
  const [loader, setLoader] = useState(false);
  const [accountsNumberList, setAccountsNumberList] = useState([]);
  const [statementAccessData, setStatementAccessData] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [bankList, setBankList] = useState([]);
  const [selectedBankLogo, setSelectedBankLogo] = useState();
  const [consents, setConsents] = useState();
  const [selectedRow, setSelectedRow] = useState(null); // New state for selected row
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setSelectedRow(row); // Set selected row
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(async () => {
    setBankList(await banksList());
  }, []);
  useEffect(async () => {
    let allProducts = [];
    setLoader(true);
    let endDate = moment().format('YYYY-MM-DD');
    let accountNumberList = [];
    let hasAffordabilityAccess = false;
    await GetAxiosInstance(`orchestrator/get-consent`, 'get').then((res) => {
      if (res.data?.codes === 200) {
        if (res.data?.data?.consents.length) {
          setConsents(res?.data?.data?.consents);
          res?.data?.data?.consents?.map((v) => {
            if (
              v.consent.data.products.filter(
                (item) =>
                  item.code === 'statement_basic' ||
                  item.code === 'statement_plus' ||
                  item.code === 'affordability_full'
              ).length
            ) {
              if (v?.accountNumber) {
                accountNumberList.push({
                  title: `XXXXXX${v.accountNumber.slice(-4)}`,
                  value: v.accountNumber,
                  icon: bankList.find((i) => i.bankName === v.bankName)?.icon,
                  bankName: bankList.find((i) => i.bankName === v.bankName)
                    ?.bankName,
                });
              }
            } else {
              hasAffordabilityAccess = true;
            }
          });
          if (!accountNumberList.length) {
            navigator('/income-and-job-verification');
          }

          let selectedProduct = res?.data?.data?.consents?.find(
            (item) => item.accountNumber === selectAccount
          );
          if (selectedProduct && selectAccount?.length) {
            endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
              'YYYY-MM-DD'
            );
          } else {
            let dateRangArray = res.data.data?.consents.map((v) => {
              return {
                startDate: v.consent?.data?.dateFrom,
                endDate: v.consent?.data?.dateTo,
              };
            });
            let allEndDate = res.data?.data?.consents.map(
              (v) => v.consent?.data?.dateTo
            );
            let maxEndDate =
              allEndDate.length &&
              allEndDate.reduce(function (a, b) {
                return a > b ? a : b;
              });

            endDate = moment(maxEndDate).format('YYYY-MM-DD');
          }
          setAccountsNumberList(accountNumberList);
          res.data?.data.consents.length &&
            res.data?.data.consents?.forEach((v) => {
              if (
                v?.consent?.data?.products.length &&
                v?.consent?.data?.products &&
                v?.consent?.data?.accountNumber ===
                  (selectAccount || accountNumberList[0]?.value)
              ) {
                v?.consent?.data?.products.forEach((i) => {
                  if (i.group === 'affordability' || i.group === 'statement') {
                    i.months?.length &&
                      i.months.forEach((j) => {
                        let obj = {
                          accountNumber: v.accountNumber,
                          statementMonth: j,
                        };
                        allProducts.push(obj);
                      });
                  }
                });
              }
            });
          setStatementAccessData(allProducts);
        }

        setConsent(res.data?.data?.consents);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(
          res?.response?.data?.message || res.message || 'Something went wrong!'
        );
      }
    });
  }, [selectAccount]);
  useEffect(() => {
    const maxObject =
      consent &&
      consent.length &&
      consent.reduce((max, currentObject) => {
        if (
          currentObject.id > max.id &&
          currentObject?.dashboardAvailable &&
          currentObject?.consent?.data?.products.filter(
            (x) => x.code === 'affordability_full'
          ).length
        ) {
          return currentObject;
        }
        return max;
      }, consent[0]);
    if (maxObject && selectAccount !== 'all' && !selectAccount) {
      setSelectAccount(maxObject?.accountNumber);
      if (bankList.length) {
        const selectedIcon = bankList.find(
          (i) => maxObject?.bankName === i.bankName
        );
        setSelectedBankLogo(selectedIcon?.icon);
      }
    }
  }, [consent, bankList]);
  useEffect(() => {
    let endDate = moment().format('YYYY-MM-DD');
    let selectAccountNumber = selectAccount
      ? selectAccount
      : accountsNumberList[0]?.value;
    let selectedConsent = consents?.find((v) => {
      return v?.accountNumber === selectAccountNumber;
    });
    setSelectedDate(selectedConsent);
    let dashboardPayload = {
      userId: userData?.id,
      date_to: moment(selectedConsent?.consent?.data?.dateTo).format(
        'YYYY-MM-DD'
      ),
      n_months: 12,
      account_id: selectAccount ? selectAccount : accountsNumberList[0]?.value,
    };

    // GetAxiosInstance(
    //   "orchestrator/get-dashboard",
    //   "post",
    //   dashboardPayload
    // ).then((res) => {
    //   if (res?.data?.codes === 200) {
    //     setDashboardData(res?.data?.data?.monthly);
    //   } else {
    //     setDashboardData([]);
    //     setLoader(false);
    //     toast.error(res?.response?.data?.message || res.message);
    //   }
    // });
  }, [selectAccount, accountsNumberList, consents]);
  const handleDownload = async (type) => {
    let payload = {
      accountNumber: selectedRow?.accountNumber,
      monthYear: selectedRow?.statementMonth,
    };
    const url =
      type === 'csv'
        ? 'orchestrator/get-csv-statement-url'
        : 'orchestrator/get-statement-url';
    await GetAxiosInstance(url, 'post', payload).then((res) => {
      if (res && res.data.codes === 200) {
        window.open(res.data?.data?.url);
      } else {
        toast.error(res?.response?.data?.message || res.message || 'Something went wrong!');
      }
    }).catch((error) => {
      toast.error(error.message || 'Something went wrong!');
    });
  };

  return (
    <>
      <DashboardUINav dashboardData={dashboardData} consent={consent} />
      {!loader ? (
        <>
          <OverviewWrapper>
            <PageBoxWrapper>
              <Box sx={{ flexGrow: 2, mt: { sm: 0, xs: 5 }, mb: 5 }}>
                <Grid container gap={2} justifyContent="flex-end">
                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Account Number
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={accountsNumberList || []}
                      disableClearable
                      getOptionLabel={(option) => option.title || ''}
                      PaperComponent={CustomPaper}
                      value={
                        accountsNumberList?.find(
                          (item) => item.value === selectAccount
                        ) || accountsNumberList[0]
                      }
                      renderOption={(props, option) => (
                        <Box
                          {...props}
                          key={props['data-option-index']}
                          component="li"
                          name={option.bankName}
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        >
                          <img
                            loading="lazy"
                            name={option.bankName}
                            width="35"
                            style={{ borderRadius: '50%' }}
                            src={option.icon}
                            alt=""
                          />
                          {option.title}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                {selectedBankLogo && (
                                  <Box
                                    component="img"
                                    src={selectedBankLogo}
                                    alt=""
                                    sx={{
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '50%',
                                    }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      onChange={(e, value) => {
                        setSelectAccount(value?.value || null);
                        setSelectedBankLogo(value?.icon);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <MainCardStyled>
                <TableContainer>
                  <Table
                    stickyHeader
                    sx={{
                      minWidth: 350,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCellHead>Statement List</TableCellHead>
                        <TableCellHead align="right">Action</TableCellHead>
                      </TableRow>
                    </TableHead>
  
                    <TableBody>
                      {statementAccessData?.map((row, index) => (
                        <TableRow hover key={index}>
                          {row?.accountNumber && row?.statementMonth && (
                            <>
                              <TableCellData>
                                {row?.statementMonth}
                              </TableCellData>
                              <TableCellData align="right">
                                <Button
                                  id="basic-button"
                                  aria-controls={
                                    open ? 'basic-menu' : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  onClick={(event) => handleClick(event, row)}
                                  endIcon={
                                    <DownloadIcon
                                      sx={{
                                        fill: (theme) =>
                                          theme.palette.background.paper,
                                      }}
                                    />
                                  }
                                >
                                  Download
                                </Button>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => handleDownload('csv')}
                                  >
                                    CSV
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => handleDownload('pdf')}
                                  >
                                    PDF
                                  </MenuItem>
                                </Menu>
                              </TableCellData>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MainCardStyled>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  opacity: '0.5',
                }}
              >
                Data Fetch from{' '}
                {moment(selectedDate?.consent?.data?.dateFrom).format(
                  'DD/MM/YYYY '
                )}{' '}
                to{' '}
                {moment(selectedDate?.consent?.data?.dateTo).format(
                  'DD/MM/YYYY '
                )}
              </Box>
            </PageBoxWrapper>
          </OverviewWrapper>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Statement;
