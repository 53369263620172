import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import AnimateButton from "../../../components/common/formStyle/AnimateButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from 'react-router-dom';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
  background: '#243842',
  fontWeight: 'bold',
}));

const portfolioRows = [
  {
    classification: 'A',
    year3: ['------', '------'],
    year4: ['------', '------'],
    recent: ['------', '------'],
  },
  {
    classification: 'B',
    year3: ['------', '------'],
    year4: ['------', '------'],
    recent: ['------', '------'],
  },
  {
    classification: 'C',
    year3: ['------', '------'],
    year4: ['------', '------'],
    recent: ['------', '------'],
  },
  {
    classification: 'D',
    year3: ['------', '------'],
    year4: ['------', '------'],
    recent: ['------', '------'],
  },
  {
    classification: 'E',
    year3: ['------', '------'],
    year4: ['------', '------'],
    recent: ['------', '------'],
  },
];

const PortfolioNg = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Grid container>
      <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="Body_semibold_20">Portfolio</Typography>
        </Box>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table
            sx={{ minWidth: 650, width: '100%' }}
            aria-label="portfolio table"
          >
            <TableHead>
              <TableRow>
                <HeadTableCell rowSpan={2}>
                  Risk Classification based on risk and arrears
                </HeadTableCell>
                <HeadTableCell colSpan={2} align="center">
                  Year 3
                </HeadTableCell>
                <HeadTableCell colSpan={2} align="center">
                  Year 4
                </HeadTableCell>
                <HeadTableCell colSpan={2} align="center">
                  Recent month or Quarter
                </HeadTableCell>
              </TableRow>
              <TableRow>
                <HeadTableCell align="center">Account</HeadTableCell>
                <HeadTableCell align="center">Loan Portfolio (%)</HeadTableCell>
                <HeadTableCell align="center">Account</HeadTableCell>
                <HeadTableCell align="center">Loan Portfolio (%)</HeadTableCell>
                <HeadTableCell align="center">Account</HeadTableCell>
                <HeadTableCell align="center">Loan Portfolio (%)</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ background: '#001823' }}>
              {portfolioRows.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{row.classification}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.year3[0]}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.year3[1]}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.year4[0]}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.year4[1]}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.recent[0]}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.recent[1]}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Buttons */}
      <Grid item xs={12} my={4} sx={{ width: '100%' }}>
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={3}
        >
          <AnimateButton>
            <Button
              variant="outlined"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                navigate('/financial-result-ng', { state: location.state })
              }
            >
              Prev
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={() =>
                navigate('/financial-summary-ng', { state: location.state })
              }
            >
              Next
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PortfolioNg;
