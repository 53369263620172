import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AnimateButton from '../../../components/common/formStyle/AnimateButton';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
  background: '#243842',
}));



const BreakdownOfLoansNg = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [data2020, setData2020] = useState([]);
  const [data2021, setData2021] = useState([]);
  const [data2022, setData2022] = useState([]);
  const [data2023, setData2023] = useState([]);
  const [data2024, setData2024] = useState([]);
  useEffect(() => {
    setData2020(state?.breakDown2020Data || []);
    setData2021(state?.breakDown2021Data || []);
    setData2022(state?.breakDown2022Data || []);
    setData2023(state?.breakDown2023Data || []);
    setData2024(state?.breakDown2024Data || []);
  }, [state]);

  return (
    <Grid container>
      <Stack>
        <Box display="flex" mt={2} gap={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Country :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedCountry?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {location.state?.selectedBank?.country.charAt(0).toUpperCase() + location.state?.selectedBank?.country.slice(1)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Bank :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedBank?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {location.state?.selectedBank?.bankName}
            </Typography>
          </Box>
        </Box>
      </Stack>
      { data2020.length > 0 ? (<Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" color="textPrimary">
            Breakdown of Loans
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            2020
          </Typography>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="breakdown of loans table">
            <TableHead>
              <TableRow>
                <HeadTableCell>Sector</HeadTableCell>
                <HeadTableCell align="right">Amount</HeadTableCell>
                <HeadTableCell align="center">Currency</HeadTableCell>
                <HeadTableCell align="right">Performing</HeadTableCell>
                <HeadTableCell align="right">Non Performing</HeadTableCell>
                <HeadTableCell align="right">Provision</HeadTableCell>
                <HeadTableCell align="right">Coverage Ratio</HeadTableCell>
                <HeadTableCell align="right">Guarantee</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2020.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background:
                      index === data2020?.length - 1 ? '#243842' : '#001823',
                  }}
                >
                  <StyledTableCell>{row.sector }</StyledTableCell>
                  <StyledTableCell align="right">{row.amount || '-'}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currency || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.performing || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.nonPerforming || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.provision || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.coverageRatio || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.guarantee  || '-'}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>) : (
        <>
        </>
      )}
      { data2021.length > 0 ? (<Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" color="textPrimary">
            Breakdown of Loans
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            2021
          </Typography>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="breakdown of loans table">
            <TableHead>
              <TableRow>
                <HeadTableCell>Sector</HeadTableCell>
                <HeadTableCell align="right">Amount</HeadTableCell>
                <HeadTableCell align="center">Currency</HeadTableCell>
                <HeadTableCell align="right">Performing</HeadTableCell>
                <HeadTableCell align="right">Non Performing</HeadTableCell>
                <HeadTableCell align="right">Provision</HeadTableCell>
                <HeadTableCell align="right">Coverage Ratio</HeadTableCell>
                <HeadTableCell align="right">Guarantee</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2021.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background:
                      index === data2021?.length - 1 ? '#243842' : '#001823',
                  }}
                >
                  <StyledTableCell>{row.sector}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount || '-' }</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currency || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.performing || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.nonPerforming || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.provision || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.coverageRatio || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.guarantee || '-'}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>) : (
        <>
        </>
      )}
      { data2022.length > 0 ? (<Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" color="textPrimary">
            Breakdown of Loans
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            2022
          </Typography>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="breakdown of loans table">
            <TableHead>
              <TableRow>
                <HeadTableCell>Sector</HeadTableCell>
                <HeadTableCell align="right">Amount</HeadTableCell>
                <HeadTableCell align="center">Currency</HeadTableCell>
                <HeadTableCell align="right">Performing</HeadTableCell>
                <HeadTableCell align="right">Non Performing</HeadTableCell>
                <HeadTableCell align="right">Provision</HeadTableCell>
                <HeadTableCell align="right">Coverage Ratio</HeadTableCell>
                <HeadTableCell align="right">Guarantee</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2022.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background:
                      index === data2022?.length - 1 ? '#243842' : '#001823',
                  }}
                >
                  <StyledTableCell>{row.sector}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount || '-'}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currency || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.performing || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.nonPerforming || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.provision  || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.coverageRatio  || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.guarantee || '-'}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>) : (
        <>
        </>
      )}
      { data2023.length > 0 ? (<Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" color="textPrimary">
            Breakdown of Loans
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            2023
          </Typography>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="breakdown of loans table">
            <TableHead>
              <TableRow>
                <HeadTableCell>Sector</HeadTableCell>
                <HeadTableCell align="right">Amount</HeadTableCell>
                <HeadTableCell align="center">Currency</HeadTableCell>
                <HeadTableCell align="right">Performing</HeadTableCell>
                <HeadTableCell align="right">Non Performing</HeadTableCell>
                <HeadTableCell align="right">Provision</HeadTableCell>
                <HeadTableCell align="right">Coverage Ratio</HeadTableCell>
                <HeadTableCell align="right">Guarantee</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2023.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background:
                      index === data2023?.length - 1 ? '#243842' : '#001823',
                  }}
                >
                  <StyledTableCell>{row.sector}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount || '-'}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currency || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.performing || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.nonPerforming  || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.provision || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.coverageRatio || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.guarantee  || '-'}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>) : (
        <>
        </>
      )}
      { data2024.length > 0 ? (<Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" color="textPrimary">
            Breakdown of Loans
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            2024
          </Typography>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="breakdown of loans table">
            <TableHead>
              <TableRow>
                <HeadTableCell>Sector</HeadTableCell>
                <HeadTableCell align="right">Amount</HeadTableCell>
                <HeadTableCell align="center">Currency</HeadTableCell>
                <HeadTableCell align="right">Performing</HeadTableCell>
                <HeadTableCell align="right">Non Performing</HeadTableCell>
                <HeadTableCell align="right">Provision</HeadTableCell>
                <HeadTableCell align="right">Coverage Ratio</HeadTableCell>
                <HeadTableCell align="right">Guarantee</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2024.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background:
                      index === data2024?.length - 1 ? '#243842' : '#001823',
                  }}
                >
                  <StyledTableCell>{row.sector}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount || '-'}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currency || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.performing || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.nonPerforming  || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.provision || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.coverageRatio || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.guarantee  || '-'}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>) : (
        <>
        </>
      )}

      {/* Buttons */}
      <Grid item xs={12} my={4} sx={{ width: '100%' }}>
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={3}
        >
          <AnimateButton>
            <Button
              variant="outlined"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                navigate('/financial-result-ng', { state: location.state })
              }
            >
              Prev
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={() =>
                navigate('/financial-Board-director-ng', {
                  state: location.state,
                })
              }
            >
              Next
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BreakdownOfLoansNg;
